import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import Collapse from 'antd/lib/collapse';

import ArrowDown from '@app/components/UI/Icons/ArrowDown';
import TextWithEllipsis from '@app/components/UI/TextWithEllipsis/TextWithEllipsis';
import { Faq } from '@app/objects/Faq';
import { ApplicationState } from '@app/store';
import { defaultColorSet } from '@app/objects/ColorSet';

const { Panel } = Collapse;

interface Props {
	faqs: Array<Faq>;
	shortAnswer?: boolean;
}

const FaqsCollapse: React.FC<Props> = ({ faqs, shortAnswer = false }) => {
	const siteSettings = useSelector((state: ApplicationState) => state.siteViewSettings.item, shallowEqual);
	const colorSet = siteSettings?.colorSet ?? defaultColorSet;

	return (
		<Collapse
			accordion
			className="faq__collapse mb10"
			expandIcon={({ isActive }) => <ArrowDown fill={colorSet.baseColor4} style={{ transform: isActive ? 'rotate(180deg)' : 'none' }} />}
			expandIconPosition="end"
			ghost
		>
			{faqs?.map((faq) => <Panel header={<h3>{faq.questionEn}</h3>} key={faq.id} className="faq__collapse-panel">
				{shortAnswer
					? <TextWithEllipsis text={faq.answerEn} count={400} />
					: <div dangerouslySetInnerHTML={{ __html: faq.answerEn }} />
				}
			</Panel>)}
		</Collapse>
	);
};

export default FaqsCollapse;
